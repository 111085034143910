import React, { useState } from "react";
import { Grid } from "@material-ui/core";

import MultiSelect from "../../../../components/MultiSelect";

// components
import {dimensionOptions, dimensionLookup} from '../Dimensions';
import { Typography } from "../../../../components/Wrappers";
import Widget from "../../../../components/Widget";
import Chart from "./Chart";

// styles
import useStyles from "../../../../pages/dashboard/styles";


const granularityOptions = [
	{value: "hour", label: "Hourly"},
	{value: "day", label: "Daily"},
	{value: "week", label: "Weekly"},
	{value: "month", label: "Monthly"},
	{value: "year", label: "Yearly"}
];


export default function ChartRenderer({
	measures,
	filters, 
	dateRange
}){


	const [dimension, setDimension] = useState([]);
	const [granularity, setGranularity] = useState(granularityOptions[1]);

	const classes = useStyles();

	var timeDimension = {
		dimension: "Sessions.timestamp",
		granularity: granularity.value,
		dateRange: dateRange 
	};

	const chart = {
		query: {
			measures: measures,
			timeDimensions: [timeDimension],
			"dimensions": dimension,
			"filters": filters,
		}
	};

	return(
	  <Widget
			bodyClass={classes.mainChartBody}
			header={
			  <div className={classes.mainChartHeader}>
				<Typography
				  variant="h5"
				  color="text"
				  colorBrightness="secondary"
				>
				  Timeline
				</Typography>
				<div style={{minWidth:"200px"}}>
					<Grid container spacing={2}>
					  
					  <Grid item style={{minWidth:"200px"}}>
						  <MultiSelect
								placeholder="Pivot by..."
								theme={classes.multiSelect}
								options={dimensionOptions}
								onChange={ ({value}) => {
									setDimension(dimensionLookup[value]);
								}  }
							/>
					  </Grid>
					  
					  <Grid item style={{minWidth:"120px"}}>
						  <MultiSelect
								placeholder="Group..."
								value={granularity}
								theme={classes.multiSelect}
								options={granularityOptions}
								onChange={ (value) => {
									setGranularity(value);
								}  }
							/>
					  </Grid>
					</Grid>
				</div>
			  </div>
			}
		>	

			<Chart vizState={chart}/>

			

		</Widget>


	)

};
