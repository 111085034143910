import moment from 'moment';



const checkPing = (ping) => {
	let now = moment();
	let pingHealth = "Good";
	let color = 'primary';
	let last = moment(ping).format('MM-DD-YYYYTHH:mm:ss')

	if(!ping){
		pingHealth = "Null"
		color = "warning";
		last = "No pings yet";
	}
	else if(now.diff(moment(ping), 'seconds') > 60) {
		pingHealth = "Poor"
		color = "secondary";
	}

	return({
		text: pingHealth,
		color: color,
		last: last
	});
}



export default checkPing;

