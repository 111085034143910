import React from "react";

// components
import Chart from "./Chart";

export default function SessionsToday(){

  var chart = {
    query: {
      "measures": [
        "Sessions.count",
        "Sessions.averageDurationSeconds",
        "Sessions.averagePageviews",
        "Sessions.bounceRate"
      ],
      "timeDimensions": [],
      "filters": [],
      "dimensions": []
    }
  };
	
	return (  <Chart vizState={chart}/>  );
}