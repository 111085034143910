import React from 'react';
import Plot from 'react-plotly.js';
import { useCubeQuery } from "@cubejs-client/react";
import { useTheme } from "@material-ui/styles";
import CenteredLoader from "../../../../components/CenteredLoader";
import {formatLineData} from '../../../../utils/chartDataFormat';



function renderChart({isLoading, resultSet, error}, theme){
  

  if(isLoading)
    return(<CenteredLoader />)

  if(error || resultSet === null)
    return(<div>{"Something went wrong:" + JSON.stringify(error) }</div>)

  let plotlyData = formatLineData(resultSet, theme);

  return(
      <Plot
          style={{height:"100%", width:"100%"}}
          data={plotlyData}
          layout={ { 
            margin:"20", 
            autosize: true, 
            xaxis: {
              showgrid: false,
              zeroline: false,

              tickfont: {
                family: theme.typography.fontFamily,
                color: theme.palette.text.hint,
                size: 14 
              }

            },
            yaxis: {
              zeroline: false,
              title: {
                text: 'Sessions [Total]',
                font: {
                  family: theme.typography.fontFamily,
                  color: theme.palette.text.hint,
                }
              },
              tickfont: {
                family: theme.typography.fontFamily,
                color: theme.palette.text.hint,
                size: 14 
              }
            }
          }}
          autoOpen={false}
          config={null}
          useResizeHandler={true}
        />


  );
}


export default function Chart({vizState}){



  const theme = useTheme();
  const { query } = vizState;
  const renderProps = useCubeQuery(query);
  return (renderChart(renderProps, theme));
}
