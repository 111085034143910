import React from 'react';
import { useCubeQuery } from "@cubejs-client/react";
import NumberFormat from 'react-number-format';
import { getNumber } from '../../../../utils/chartDataFormat';
import CenteredLoader from "../../../../components/CenteredLoader";
import { useTheme } from "@material-ui/styles";
import { Typography } from "../../../../components/Wrappers";
import Widget from "../../../../components/Widget";

import {
  LineChart,
  Line,
} from "recharts";


import {
  Grid,
} from "@material-ui/core";


// styles
import useStyles from "./styles";


function renderChart({isLoading, resultSet, error}, theme, classes){
  
  if(isLoading)
    return(
       <Widget
            title="Sessions Total"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
          <div className={classes.visitsNumberContainer}>
            <CenteredLoader />
          </div>
      </Widget>
    );

  if(error || resultSet === null)
    return(
      <Widget
            title="Sessions Total"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
          <div className={classes.visitsNumberContainer}>
            {"Something went wrong:" + JSON.stringify(error) }
          </div>
      </Widget>
    );

  let sessionsCount = getNumber(resultSet, "Sessions.count");
  let sessionsAverageDurationSeconds = getNumber(resultSet, "Sessions.averageDurationSeconds");
  let sessionsAveragePageviews = getNumber(resultSet, "Sessions.averagePageviews");
  let sessionsBounceRate = getNumber(resultSet, "Sessions.bounceRate");

  return(
    <Widget
            title="Sessions Total"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
    <div className={classes.visitsNumberContainer}>              
      <div className={classes.totalValue}>
        <Typography variant="h2">
          <NumberFormat value={sessionsCount} displayType={'text'} thousandSeparator={true} renderText={value => <span>{value}</span>} />
        </Typography>
        <Typography style={{display:'none'}} color={true ? "success" : "secondary"}>
            &nbsp;{true ? "+" : "-"}
            {152}
        </Typography>
      </div>
      <LineChart
        width={55}
        height={30}
        data={[
          { value: 10 },
          { value: 15 },
          { value: 10 },
          { value: 17 },
          { value: 18 },
        ]}
        margin={{ left: theme.spacing(2) }}
      >
        <Line
          type="natural"
          dataKey="value"
          stroke={theme.palette.primary.main}
          strokeWidth={2}
          dot={false}
        />
      </LineChart>
    </div>
    <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography color="text" colorBrightness="secondary">
              Avg Length
            </Typography>
            <div className={classes.totalValue}>
              <Typography size="md">
                <NumberFormat 
                  value={sessionsAverageDurationSeconds} 
                  displayType={'text'} 
                  thousandSeparator={true} 
                  fixedDecimalScale={true} 
                  decimalScale={1} 
                  suffix='s'
                  renderText={value => <span>{value}</span>} />
              </Typography>
              <Typography style={{display:'none'}} color={true ? "success" : "secondary"}>
                  &nbsp;{true ? "+" : "-"}
                  {3.25}%
              </Typography>
            </div>
          </Grid>
          <Grid item>
            <Typography color="text" colorBrightness="secondary">
              Avg Pageviews
            </Typography>

            <div className={classes.totalValue}>
              <Typography size="md">
                <NumberFormat 
                  value={sessionsAveragePageviews} 
                  displayType={'text'} 
                  thousandSeparator={true} 
                  fixedDecimalScale={true} 
                  decimalScale={1} 
                  suffix=''
                  renderText={value => <span>{value}</span>} />
              </Typography>
              <Typography style={{display:'none'}} color={false ? "success" : "secondary"}>
                &nbsp;{false ? "+" : "-"}
                {0.35}%
              </Typography>
            </div>
          </Grid>
          <Grid item>
            <Typography color="text" colorBrightness="secondary">
              Bounce Rate
            </Typography>
            <div className={classes.totalValue}>
              <Typography size="md">
                <NumberFormat 
                  value={sessionsBounceRate} 
                  displayType={'text'} 
                  thousandSeparator={true} 
                  fixedDecimalScale={true} 
                  decimalScale={1} 
                  suffix='%'
                  renderText={value => <span>{value}</span>} />
              </Typography>
              <Typography style={{display:'none'}} color={true ? "success" : "secondary"}>
                &nbsp;{true ? "-" : "+"}
                {1.87}%
              </Typography>
            </div>
          </Grid>
        </Grid>
     </Widget>
  );
}


export default function Chart({vizState}){
  const theme = useTheme();
  const classes = useStyles();
  const { query } = vizState;
  const renderProps = useCubeQuery(query);
  return (renderChart(renderProps, theme, classes));
}
