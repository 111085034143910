import React from "react";
import { Grid } from "@material-ui/core";

// components
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";

// styles
import useStyles from "./styles";

// Util
import auth from "../../utils/auth"

export default function Campaigns(props) {
  var classes = useStyles();

  return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{"height":"100%"}}
      >
        <PageTitle title={"Welcome, "+auth.getUserInfo().username+ "! "} height="100%"/>
        <Typography className={classes.typo} variant="h2" size="sm">Select a campaign to get started</Typography>

      </Grid>

  );
}
