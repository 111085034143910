import React from "react";

var CampaignStateContext = React.createContext();
var CampaignDispatchContext = React.createContext();

function campaignReducer(state, action) {
  switch (action.type) {
    case "SELECT_CAMPAIGN":
      return { ...state, campaignId: action.value };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function CampaignProvider({ campaign, children }) {
  

  var [state, dispatch] = React.useReducer(campaignReducer, {
    campaignId: campaign
  });
  return (
    <CampaignStateContext.Provider value={state}>
      <CampaignDispatchContext.Provider value={dispatch}>
        {children}
      </CampaignDispatchContext.Provider>
    </CampaignStateContext.Provider>
  );
}

function useCampaignState() {
  var context = React.useContext(CampaignStateContext);
  if (context === undefined) {
    throw new Error("useCampaignState must be used within a CampaignProvider");
  }
  return context;
}

function useCampaignDispatch() {
  var context = React.useContext(CampaignDispatchContext);
  if (context === undefined) {
    throw new Error("useCampaignDispatch must be used within a CampaignProvider");
  }
  return context;
}

export { CampaignProvider, useCampaignState, useCampaignDispatch, setCampaign };

// ###########################################################
function setCampaign(dispatch, campaignId) {
  dispatch({
    type: "SELECT_CAMPAIGN",
    value: campaignId
  });
}
