import React from 'react'
import { get, map } from 'lodash'

import { Grid, Button } from '@material-ui/core';
import PageTitle from "../../components/PageTitle"

import VersionFormInput from '../../components/VersionFormInput'


export default function VersionForm (props) {

	const fields = props.fields;
	const content = props.content;

	const title = get(content,'title','');

	return(
		<div style={{"overflow":"hidden"}}>
  		<PageTitle title={title}  />
		<Grid
		  container
		  spacing={10}
		  direction="column"
		>

			{map(fields, (field, key) => (
				<Grid 
					key={key}
					item
				>		
				<VersionFormInput 
					label={get(field, 'title')}
					field={field} 
					value={get(content, get(field, 'name'),'')} 
					handleChange={props.handleChange } 
					readOnly={props.readOnly} 
					openModal={props.openModal}
					clearAll={props.clearAll}
					/>
				</Grid>
				)
			)}

			<Grid item>
				{ props.readOnly !== true ?
					<Button 
						onClick={props.onSubmit}
						variant="contained" 
						color="primary"
					>
						Submit
					</Button>
					: 
					<div />
				 }
			</Grid>
		</Grid>
	</div>
	);
}