import React from "react";
import {Grid, CircularProgress } from "@material-ui/core";

export default function FullScreenLoader(){
	return(
		<Grid 
            container
            justify="center"
            alignItems="center"
            style={{"height":"100VH"}}
            >
            <CircularProgress size={54}  />
    	</Grid>
	)
}