import React from "react";
import LogRocket from 'logrocket';

import Axios from "../utils/Axios"
import auth from "../utils/auth"


var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  console.log(action);

  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  

  // NOTE: Find better way to clear any old storage
  if(!auth.getToken() || !auth.getUserInfo()){
    auth.clearToken();
    auth.clearUserInfo();
    auth.clearAppStorage();
  }


  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!auth.getToken(),
  });


  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  ClearAllStorage();


  const requestURL = '/auth/local';
  const data = {
    "identifier": login,
    "password": password
  }


  Axios.post(requestURL, data).then( res => {
    setTimeout(() => {
      auth.setToken(res.data.jwt, true)
      auth.setUserInfo(res.data.user, true)

      console.log(res.data.user);

      LogRocket.identify(res.data.user.id, {
        name: res.data.user.username,
        email: res.data.user.email,

        // Add your own custom user variables here, ie:
        organization: res.data.user.organization.name
      });

      setError(null)
      setIsLoading(false)
      dispatch({ type: 'LOGIN_SUCCESS' })
      history.push('/app')
    }, 2000);


  })
  .catch(err => {
    setError(true); 
    setIsLoading(false);
  });
}

function signOut(dispatch, history) {
  ClearAllStorage();
  history.push("/login");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
}


function ClearAllStorage(){
  auth.clearToken();
  auth.clearUserInfo();
  auth.clearAppStorage();
}
