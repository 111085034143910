import React from "react";
import Widget from "../../../../components/Widget";
import Chart from "./Chart";

import {
	Grid,
} from "@material-ui/core";

// styles
import useStyles from "../../../../pages/dashboard/styles";


export default function DisplaysBreakdownPie(props){

	var classes = useStyles();

	var chart = {
		query: {
			"measures": [
				"Displays.count"
			],
			"timeDimensions": [],
			"dimensions": [
				"Locations.province"
			],
			"filters": []
		}

	};


	return(

		<Widget 
			title="Display Distribution" 
			upperTitle 
			className={classes.card}
            bodyClass={classes.fullHeightBody}
            >
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Chart vizState={chart}/>
				</Grid>
			</Grid>
		</Widget>

	);
}