import React from 'react';
import { useCubeQuery } from "@cubejs-client/react";
import NumberFormat from 'react-number-format';
//import { getNumber } from '../../../../utils/chartDataFormat';
import CenteredLoader from "../../../../components/CenteredLoader";
import { useTheme } from "@material-ui/styles";

import { Typography }  from "../../../../components/Wrappers";
import Widget from "../../../../components/Widget";

import {
  Grid
} from "@material-ui/core";


// styles
import useStyles from "./styles";


function renderChart({isLoading, resultSet, error}, theme, classes){

  if(isLoading)
    return(
       <Widget
            title="Display Performance"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
          <div className={classes.visitsNumberContainer}>
            <CenteredLoader />
          </div>
      </Widget>
    );

  if(error || resultSet === null)
    return(
      <Widget
            title="Display Performance"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
          <div className={classes.visitsNumberContainer}>
            {"Something went wrong:" + JSON.stringify(error) }
          </div>
      </Widget>

    );

  //let sessionsCount = getNumber(resultSet, "Sessions.count");
  //let sessionsAverageDurationSeconds = getNumber(resultSet, "Sessions.averageDurationSeconds");
  //let sessionsAveragePageviews = getNumber(resultSet, "Sessions.averagePageviews");
  //let sessionsBounceRate = getNumber(resultSet, "Sessions.bounceRate");

  return(
    <Widget
            title="Display Performance"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
    <div className={classes.visitsNumberContainer}>              
      <div className={classes.totalValue}>
        <Typography variant="h2" color={true ? "success" : "secondary"}>
            <NumberFormat value={100} displayType={'text'} thousandSeparator={true} suffix="%" renderText={value => <span>{value}</span>} />
        </Typography>
        <Typography variant="subtitle2" color={"text"} colorBrightness={"feather"}>
           &nbsp; currently online
        </Typography>
      </div>
    </div>
    <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
          <Grid item>
            <Typography color="text" colorBrightness="secondary">
              Avg Downtime
            </Typography>
            <div className={classes.totalValue}>
              <Typography size="md">
                <NumberFormat 
                  value={"-"} 
                  displayType={'text'} 
                  thousandSeparator={true} 
                  fixedDecimalScale={true} 
                  decimalScale={1}
                  renderText={value => <span>{value}</span>} />
              </Typography>
              <Typography variant="subtitle2" color={"text"} colorBrightness={"feather"}>
                  &nbsp; minutes/month
              </Typography>
            </div>
          </Grid>
          <Grid item>
            <Typography color="text" colorBrightness="secondary">
              Errors
            </Typography>

            <div className={classes.totalValue}>
              <Typography size="md">
                <NumberFormat 
                  value={'-'} 
                  displayType={'text'} 
                  thousandSeparator={true} 
                  fixedDecimalScale={true} 
                  decimalScale={0} 
                  suffix=''
                  renderText={value => <span>{value}</span>} />
              </Typography>
              <Typography style={{display:'none'}} color={false ? "success" : "secondary"}>
                &nbsp;{false ? "-" : "+"}
                {1}
              </Typography>
            </div>
          </Grid>
    </Grid>
     </Widget>
  );
}


export default function Chart({vizState}){
  const theme = useTheme();
  const classes = useStyles();
  const { query } = vizState;
  const renderProps = useCubeQuery(query);
  return (renderChart(renderProps, theme, classes));
}
