import React from "react";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

// cube 
import cubejs from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";


// pages
import Dashboard from "../../pages/dashboard";
import Maps from "../../pages/map";
import Displays from "../../pages/displays";
import Campaigns from "../../pages/campaigns";
import Versions from "../../pages/versions";
import VersionsView from "../../pages/versionsView";
import VersionsNew from "../../pages/versionsNew";
import Library from "../../pages/library";
import Sessions from "../../pages/sessions";
import Events from "../../pages/events";
import Pageviews from "../../pages/pageviews";

// context
import { CampaignProvider } from "../../context/CampaignContext";
import { useLayoutState } from "../../context/LayoutContext";


const jwt = require('jsonwebtoken');


let API_URL;

if (process.env.NODE_ENV === 'production') {
  API_URL = process.env.REACT_APP_CUBE_API_URL;
}
else {
  API_URL = process.env.REACT_APP_CUBE_API_URL;
  //API_URL = "http://localhost:4000";
}




const CUBE_API_SECRET = process.env.REACT_APP_CUBE_API_SECRET;
const CUBEJS_TOKEN = jwt.sign({}, CUBE_API_SECRET, { expiresIn: '30d' });
const cubejsApi = cubejs(
  CUBEJS_TOKEN, 
  { apiUrl: `${API_URL}/cubejs-api/v1` }
);




function Layout(props) {
  var classes = useStyles();

  let campaignId = props.match.params.id;
  if(campaignId === null || campaignId === 'select'){
    campaignId = -1;
  }

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <CampaignProvider campaign={campaignId}>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />

            <CubeProvider cubejsApi={cubejsApi}>
              <Switch>
                <Route {...props} path="/app/select" component={Campaigns} />
                <Route {...props} path="/app/:id/analytics/sessions" component={Sessions} exact />
                <Route {...props} path="/app/:id/analytics/events" component={Events} exact />
                <Route {...props} path="/app/:id/analytics/pageviews" component={Pageviews} exact />
                <Route {...props} path="/app/:id/campaigns" component={Campaigns} exact />
                <Route {...props} path="/app/:id/dashboard" component={Dashboard} />
                <Route {...props} path="/app/:id/displays" component={Displays} />
                <Route {...props} path="/app/:id/map" component={Maps} />
                <Route {...props} path="/app/:id/versions" component={Versions} exact />
                <Route {...props} path="/app/:id/versions/view" component={Versions} exact />
                <Route {...props} path="/app/:id/versions/view/:vid" component={VersionsView} exact />
                <Route {...props} path="/app/:id/versions/new" component={VersionsNew} exact />
                <Route {...props} path="/app/:id/library" component={Library} exact />
              </Switch>
            </CubeProvider>
          </div>
        </CampaignProvider>
    </div>
  );
}

export default withRouter(Layout);
