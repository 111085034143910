import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import Themes from "./themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";

import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import auth from './utils/auth'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import LogRocket from 'logrocket';
LogRocket.init('hwr03w/instinct-iot');

require('dotenv').config();



// APOLLO SETUP
const httpLink = createHttpLink({
  //uri: 'http://localhost:1337/graphql'
  uri: process.env.REACT_APP_INSTINCT_CMS + '/graphql'
})


const authLink = setContext((_, { headers }) => {
  const token = auth.getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})



// APOLLO CLIENT
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})


ReactDOM.render(
	  <LayoutProvider>
	    <UserProvider>
	      <ThemeProvider theme={Themes.default}>
	        <CssBaseline />
			     <ApolloProvider client={client}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
        	    <App />
            </MuiPickersUtilsProvider>
		       </ApolloProvider>
	      </ThemeProvider>
	    </UserProvider>
	  </LayoutProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
