import React, {useState} from "react"
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { get } from 'lodash'

// components
import FullScreenLoader from "../../components/FullScreenLoader"
import VersionForm from "../../components/VersionForm"


const GET_VERSION_FORM = gql`
  query campaignByOrganization($id: ID!){
    campaignByOrganization(id: $id){
        id,
        versions{
          id,
          title,
          content
        },
        contentStructure
    }
  }
`;


function onCompleted(data, versionId, setContentState, setFieldState){
    if(data === null || data === undefined) return;
    const fields = data.campaignByOrganization.contentStructure.fields;
    const versions = data.campaignByOrganization.versions;
    const version = versions.find(item => { return(parseInt(item.id) === parseInt(versionId)) });
    const contentArray = get(version,'content');
    let content = {};
  for(let i in contentArray){
    let obj = contentArray[i];
    content[obj.name] = obj.value;
  }
  setContentState(content);
  setFieldState(fields)
}


export default function Versions(props) {

  const campaignId = props.match.params.id;
  const versionId = props.match.params.vid;

  // local
  var [contentState, setContentState] = useState(null);
  var [fieldState, setFieldState] = useState(null);



  return (
    <Query query={GET_VERSION_FORM} variables={{id: campaignId}} onCompleted={(data) => {return onCompleted(data, versionId, setContentState, setFieldState)} } >
      {({ loading, error, data }) => {
        if(loading ) return ( <FullScreenLoader /> )
        if (error) {
          console.log(error);
          return <div>{JSON.stringify(error)}</div>
        }

        return(
          <div>
            <VersionForm 
              version={versionId} 
              content={contentState} 
              fields={fieldState} 
              readOnly={true} 
              />
          </div>
        )
      }}
    </Query>

  );
}