import React from 'react';
import Plot from 'react-plotly.js';
import { useCubeQuery } from "@cubejs-client/react";
import { useTheme } from "@material-ui/styles";
import CenteredLoader from "../../../../components/CenteredLoader";
import {formatBarData} from '../../../../utils/chartDataFormat';


let options = [];


function renderChart({isLoading, resultSet, error}, theme, callback){
  

  if(isLoading)
    return(<CenteredLoader />)

  if(error || resultSet === null)
    return(<div>{"Something went wrong:" + JSON.stringify(error) }</div>)

  let data = resultSet.loadResponse.data;
  data.forEach( (item) => {
    let value = item['Events.pageTitle'];
    if(value !== null && value !== undefined)
      options.push({value: value, label: value});
  });

  let plotlyData = formatBarData(resultSet, theme);

  return(
    <Plot
        style={{height:"100%", width:"100%"}}
        data={plotlyData}
        layout={ { 
          margin:"20", 
          autosize: true, 
          xaxis: {
            showgrid: false,
            zeroline: false,

            tickfont: {
              family: theme.typography.fontFamily,
              color: theme.palette.text.hint,
              size: 14 
            }

          },
          yaxis: {
            zeroline: false,
            title: {
              text: 'Pageviews [Total]',
              font: {
                family: theme.typography.fontFamily,
                color: theme.palette.text.hint,
              }
            },
            tickfont: {
              family: theme.typography.fontFamily,
              color: theme.palette.text.hint,
              size: 14 
            }
          }
        }}
        autoOpen={false}
        config={null}
        useResizeHandler={true}
      />


  );
}


export default function Chart({vizState, callback}){
  const theme = useTheme();
  const { query } = vizState;
  const renderProps = useCubeQuery(query);
  return (renderChart(renderProps, theme, callback));
}
