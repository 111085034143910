import React from 'react';
import { useCubeQuery } from "@cubejs-client/react";
import WidgetTable from "../../../../components/WidgetTable";

function renderChart({isLoading, resultSet, error}, callback){
  if(isLoading)
    return(<WidgetTable 
      title="Breakdown"
      options={{selectableRows: 'none', textLabels: { body: { noMatch:"Loading..." }}}} 
    />)

  if(error || resultSet === null)
    return(<div>{"Something went wrong:" + JSON.stringify(error) }</div>)

  const { data } = resultSet.loadResponse;
  const tableData = data.map( item => {
    return {
      storeNumber: item["Locations.storeNumber"],
      city: item["Locations.city"],
      province: item["Locations.province"],
      count: parseInt(item["Sessions.count"]),
    }
  });


  return(
    <WidgetTable 
      title="Breakdown"
      data={tableData}
      columns={[
        {name:"storeNumber", label: "Store Number" },
        {name:"city", label: "City" },
        {name:"province", label: "State / Province" },
        {name:"count", label: "Total Sessions"}
      ]}
      options={{selectableRows: 'none', textLabels:{body:{noMatch:"No results found."}}}} 
    />


  );
}


export default function Chart({vizState, callback}){
  const { query } = vizState;
  const renderProps = useCubeQuery(query);
  return (renderChart(renderProps, callback));
}
