import React from "react";
import { useCubeQuery } from "@cubejs-client/react";
import CenteredLoader from "../../../../components/CenteredLoader";
import Plot from 'react-plotly.js';
import { formatPieData } from '../../../../utils/chartDataFormat';
import { useTheme } from "@material-ui/styles";

function renderChart({isLoading, resultSet, error}, theme){
    
  if(isLoading)
    return(<CenteredLoader />)

  if(error || resultSet === null)
    return(<div>{"Something went wrong:" + JSON.stringify(error) }</div>)

  let plotlyData = formatPieData(resultSet, theme.palette.chart.contrast);

  return(
    <Plot
        style={{margin: "auto", height:"150px", width:"200px"}}
        data={plotlyData}
        layout={ { 
          margin: {"t": 0, "b": 0, "l": 0, "r": 0}, 
          autosize: true,
          showlegend: true
        }}
        autoOpen={false}
        useResizeHandler={true}
        config={{ displayModeBar: false}}
      />


  );
}

export default function Chart({vizState}){
  var theme = useTheme();
  const { query } = vizState;
  const renderProps = useCubeQuery(query);
  return (renderChart(renderProps, theme));
}