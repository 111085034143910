import React from 'react'
import { get, map } from 'lodash'
import moment from 'moment'

import { Select, MenuItem, CircularProgress } from "@material-ui/core";

import { Query } from 'react-apollo'
import gql from 'graphql-tag'
const GET_VERSIONS = gql`
	query campaignByOrganization($id: ID!){
		campaignByOrganization(id: $id) {
			id,
			title,
			versions{
				id,
				created_at,
				title
			}
		}
	}
`;


export default function VersionDropdown({
	id,
	value,
	onChange
}) {

	
  	return(<div  style={{padding:"12px"}}>
  		<Query query={GET_VERSIONS} variables={{id: id}}>
			{({ loading, error, data }) => {

				if(loading) return ( <CircularProgress size={14}  /> )

				const versions = data.campaignByOrganization.versions;

				return(
			  		<div className="field">
						<div className="control"  style={{'textAlign':'right'}}>
				  		<div className="select">
				  		<Select value={value} onChange={onChange} >
							<MenuItem value={-1} >Select a version</MenuItem>
							{ map(versions, (version, key) => (
								<MenuItem key={key} value={get(version,'id')}> {get(version,'title')} ({moment(get(version,'created_at')).format('MM-DD-YYYY')}) </MenuItem>
							))}
						</Select>
						</div>
						</div>
					</div>
				)
			}}
		</Query>
		</div>
	)
	
}