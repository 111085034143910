import React, { useState } from "react";
import { Grid } from "@material-ui/core";

// components
import MultiSelect from "../../../../components/MultiSelect";
import { dimensionOptions, dimensionLookup} from '../Dimensions';
import { Typography } from "../../../../components/Wrappers";
import Widget from "../../../../components/Widget";
import Chart from "./Chart";

// styles
import useStyles from "../../../../pages/dashboard/styles";

export default function ChartRenderer({
	measures,
	filters, 
	dateRange
}){

	const [dimension, setDimension] = useState({label: "Event Category", value: "eventCategory"});

	const classes = useStyles();
	const chart = {
		query: {
			"measures": measures,
			"timeDimensions": [{
				dimension: "Sessions.timestamp",
				dateRange: dateRange 
			}],
			"filters": filters,
			"dimensions": dimensionLookup[dimension.value],
		}
	};

	return(
		<Widget
			bodyClass={classes.mainChartBody}
			header={
				<div className={classes.mainChartHeader}>
				<Typography
					variant="h5"
					color="text"
					colorBrightness="secondary"
				>
					Comparison
				</Typography>
				<div>
				<Grid container spacing={2}>
					<Grid item style={{minWidth:"200px"}}>
						<MultiSelect
							placeholder="Group by..."
							value={dimension}
							theme={classes.multiSelect}
							options={dimensionOptions}
							onChange={ (value) => {
								setDimension(value);
							}  }
						/>
					</Grid>
				</Grid>
				</div>
				</div>
			}
		>	

			<Chart vizState={chart} />

			

		</Widget>


	);

};
