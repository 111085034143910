import React, { useState } from "react";
import moment from 'moment';
import {
  Grid,
} from "@material-ui/core";


// components
import PageTitle from "../../components/PageTitle";

import EventsFilters from "./components/EventsFilters";
import EventsLineGraph from "./components/EventsLineGraph";
import EventsBarGraph from "./components/EventsBarGraph";
import EventsTable from "./components/EventsTable";
import EventsMap from "./components/EventsMap";

function generateFilter(array){
  let filters = [];
  array.forEach((item => {
    if (item.values.length > 0) filters.push(item);
  }));
  return(filters)
}

export default function Events(props) {

  const [showFilters, setShowFilters] = useState('none');
  const [startDate, setStartDate] = useState(  moment().add(-3, 'months').startOf('day')  );
  const [endDate, setEndDate] =     useState(  moment().endOf('day')  );

  const [measureState, setMeasureState] = useState(["Events.countEvents"]);
  
  const [retailerFilter, setRetailerFilter] = useState([]);
  const [storeNumberFilter, setStoreNumberFilter] = useState([]);
  
  const [countryFilter, setCountryFilter] = useState([]);
  const [provinceFilter, setProvinceFilter] = useState([]);
  const [cityFilter, setCityFilter] = useState([]);

  const [categoryFilter, setCategoryFilter] = useState([]);
  const [labelFilter, setLabelFilter] = useState([]);
  const [actionFilter, setActionFilter] = useState([]);


  const filters = generateFilter([
    retailerFilter,
    storeNumberFilter,
    countryFilter,
    provinceFilter,
    cityFilter,
    categoryFilter,
    labelFilter, 
    actionFilter
  ]);


  return (
    <div>
      
      <PageTitle 
        title="Events" 
        datetimePicker
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        button={showFilters==='none' ? 'Options' : 'Hide'} 
        onClick={() => {
        if(showFilters === 'block') setShowFilters('none');
        else setShowFilters('block');
      }}/>


      <Grid container spacing={4}>

        <Grid item lg={12} md={12} sm={12} xs={12} style={{display: showFilters}}>
          <EventsFilters 
            measureState={measureState}
            setMeasureState={setMeasureState}

            retailerFilter={retailerFilter}
            setRetailerFilter={setRetailerFilter}
            storeNumberFilter={storeNumberFilter}
            setStoreNumberFilter={setStoreNumberFilter}

            countryFilter={countryFilter}
            setCountryFilter={setCountryFilter}
            provinceFilter={provinceFilter}
            setProvinceFilter={setProvinceFilter}
            cityFilter={cityFilter}
            setCityFilter={setCityFilter}

            categoryFilter={categoryFilter}
            setCategoryFilter={setCategoryFilter}
            labelFilter={labelFilter}
            setLabelFilter={setLabelFilter}
            actionFilter={actionFilter}
            setActionFilter={setActionFilter}
          />
        </Grid>

        <Grid item lg={6} md={12} sm={12} xs={12}>
          <EventsLineGraph measures={measureState} filters={filters} dateRange={[startDate, endDate]} />
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <EventsMap measures={measureState} filters={filters} dateRange={[startDate, endDate]} />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <EventsBarGraph measures={measureState} filters={filters} dateRange={[startDate, endDate]} />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <EventsTable measures={measureState} filters={filters} dateRange={[startDate, endDate]} />
        </Grid>
      </Grid>
    </div>
  );
}


/*
<Grid item lg={12} md={12} sm={12} xs={12}>
          <Plot
            style={{height:"100%", width:"100%"}}
            data={ data }
            layout={ layout }
            autoOpen={false}
            config={null}
            useResizeHandler={true}
          />
        </Grid>

        */