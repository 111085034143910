import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({

	multiSelect:{
		overflowY: "visible"

	}


}));
