import React from "react";
import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import { CircularProgress } from "@material-ui/core";

import {
  MenuItem,
  Select
} from "@material-ui/core";


const GET_CAMPAIGN = gql`
	{
		campaignsByOrganization {
			id,
			title
		}
	}
`;

export default function CampaignsDropdown({
	value,
	onChange,
}){


	return(<div>
		<Query query={GET_CAMPAIGN}>
		{({ loading, error, data }) => {
			if(loading) return ( <CircularProgress size={26}  /> )
			if (error) {
				return <div>Error</div>
			}

			const campaigns = data.campaignsByOrganization;

			return(
				<Select
		          value={value}
		          onChange={onChange}
		          autoWidth
		          style={{"color": "white"}}
		        >
		          <MenuItem value={-1}> Select a Campaign </MenuItem>
		          {campaigns.map((campaign, key) => 
		          	<MenuItem 
		          		key={key} 
		          		value={campaign.id}> {campaign.title}  </MenuItem>
		          )}

		        </Select>
			)
        }}
		</Query>
		</div>
	)
}