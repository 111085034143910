export const dimensionOptions = [
	{label: "None", value: "none"},
	{label: "Retailer", options: [
		{label: "Retailer", value: "retailer"},
		{label: "Store Number", value: "storeNumber"}
	]},
	{label: "Location", options: [
		{label: "City", value: "city"},
		{label: "Province", value: "province"},
		{label: "Country", value: "country"},
	]},
	{label: "Event", options: [
		{label: "Event Category", value: "eventCategory"},
		{label: "Event Label", value: "eventLabel"},
		{label: "Event Action", value: "eventAction"},
	]},
	
];

export const dimensionLookup = {
	"none": [],
	"eventCategory": ["Events.eventCategory"],
	"eventLabel": ["Events.eventLabel"],
	"eventAction": ["Events.eventAction"],
	"city": ["Locations.city"],
	"province": ["Locations.province"],
	"country": ["Locations.country"],
	"retailer": ["Locations.retailer"],
	"storeNumber": ["Locations.storeNumber"]
};
