
import React from "react";

// components
import Widget from "../../../../components/Widget";
import Chart from "./Chart";

// styles
import useStyles from "../../../../pages/dashboard/styles";





export default function ChartRenderer({filters, dateRange}){

	const classes = useStyles();
	const chart = {
		query: {
			"measures": [ "Sessions.count" ],
			"timeDimensions": [{
				dimension: "Sessions.timestamp",
				dateRange: dateRange 
			}],
			"filters": filters,
			"dimensions": ["Locations.latitude","Locations.longitude", "Locations.retailer", "Locations.storeNumber"],
		}
	};



	return(
		<Widget
			bodyClass={classes.mainChartBody}
			disableWidgetHeader
		>	
			<Chart vizState={chart} />
		</Widget>
	)

};