import React from 'react';
import MUIDataTable from "mui-datatables";
import useStyles from "./styles";


export default function WidgetTable(props){
	const classes = useStyles();
	return(
		<MUIDataTable
		className={classes.widgetRoot}
		{ ...props }
		>
		</MUIDataTable>
	);
}