import React from 'react';
import Plot from 'react-plotly.js';
import { useCubeQuery } from "@cubejs-client/react";
import CenteredLoader from "../../../../components/CenteredLoader";

import { useTheme } from "@material-ui/styles";
import useStyles from "./styles";


const layout = {
    dragmode: "zoom",
    mapbox: { style: "carto-positron", center: { lat: 38, lon: -90 }, zoom: 3 },
    coloraxis: {
      colorscale: "Viridis", 
      showscale: false
    },
    bgcolor: '#F00',
    margin: { r: 0, t: 0, b: 0, l: 0 },
    legend: {
      x: .99,
      xanchor: 'right',
      y: 0.96
    }
};


function renderChart({isLoading, resultSet, error}, theme, classes){

	if(isLoading)
		return(<CenteredLoader />)

	if(error || resultSet === null)
		return(<div>{"Something went wrong:" + JSON.stringify(error) }</div>)


	const data = resultSet.loadResponse.data;

  console.log(data);

  const lat = data.map(item => item["Locations.latitude"]);
  const lon = data.map(item => item["Locations.longitude"]);
  const count = data.map(item => item["Sessions.count"]);
  const countMax = Math.max(...count);
  const countMin = Math.min(...count);

  let radius = [];
  let z = [];
  const radiusBase = 10;
  const radiusConst = 5;

  count.forEach(value => {
    let r = (value - countMin)/(countMax - countMin);
    radius.push( Math.round( r * radiusBase + radiusConst)  );
    z.push(parseInt(value));
  })




  const text = data.map(item => 
    item["Sessions.count"] + " Sessions"
  );


  const plotData = [{
    name: "Point",
    type: "densitymapbox",
    coloraxis: 'coloraxis',
    text: text,
    lat: lat,
    lon: lon,
    z: z,
    radius: 10
  }];


  return(<div className={classes.mapContainer}>
      <Plot
      
        style={{height:"600px", width:"100%"}}
        data={ plotData }
        layout={ layout }
        autoOpen={false}
        config={null}
        useResizeHandler={true}
      />
    </div>
  );
}


export default function Chart({vizState, callback}){
	const theme = useTheme();
  const classes = useStyles();

	const { query } = vizState;
	const renderProps = useCubeQuery(query);

	return( renderChart(renderProps, theme, classes) );
}
