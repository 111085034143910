import React from "react";
import {
	Grid,
} from "@material-ui/core";

import { useCubeQuery } from "@cubejs-client/react";

// components
import MultiSelect from "../../../../components/MultiSelect";
import { Typography } from "../../../../components/Wrappers";
import CenteredLoader from "../../../../components/CenteredLoader";
import Widget from "../../../../components/Widget";
import { sortBy, uniqBy } from 'lodash';

import { useTheme } from "@material-ui/styles";
import useStyles from "./styles";

  	

const query = {
  "measures": [
    "Events.countPageviews"
  ],
  "timeDimensions": [],
  "dimensions": [
  	"Locations.retailer",
    "Locations.storeNumber",
  	"Locations.country",
    "Locations.province",
    "Locations.city",
    "Events.page",
    "Events.pageTitle"
  ],
  "filters": []
};





function filterCallback(value, dimension, setFilter){
	let stateValues = [];
	if(value !== null && value !== undefined){
		value.forEach((item) => {
			stateValues.push(item.value);
		});
	}
	let state = {
		"dimension": dimension,
		"operator": "equals",
		"values": stateValues
	};
	setFilter(state);
}




function getOptions(data, key){

	const uniqueSortedItems = sortBy(uniqBy(data, key), key)
	
	let options = [];
	uniqueSortedItems.forEach(item => {
		if (item[key] !== null && item[key] !== undefined){
			options.push({label: item[key], value: item[key]});
		}
	})

	return([
		options
		, data
	]);
}



function getLimitedOptions(data, key, limitFilter){
	
	if(!limitFilter) return( getOptions(data, key) );
	if(limitFilter.values.length < 1) return( getOptions(data, key) );
	const {dimension, values} = limitFilter;
	const filteredData = data.filter(item => {
		return(values.includes(item[dimension]))
	} );

	return( getOptions(filteredData, key) );
}



function render({
	startDate, setStartDate,
	endDate, setEndDate,

	measureState, setMeasureState,

	retailerFilter, setRetailerFilter,
    storeNumberFilter, setStoreNumberFilter,

    countryFilter, setCountryFilter,
    provinceFilter, setProvinceFilter,
    cityFilter, setCityFilter,

}, {isLoading, resultSet, error}, theme, classes){

	

	if(isLoading)
		return(<CenteredLoader />);

	if(error || resultSet === null)
		return(<div>{"Something went wrong:" + JSON.stringify(error) }</div>);

	const data = resultSet.loadResponse.data;
	let filteredData;



	const measureOptions = [{label: "Count Sessions", value: "count"}];


	let retailerOptions;
	let storeNumberOptions;

	let countryOptions;
	let provinceOptions;
	let cityOptions;

	[ retailerOptions, filteredData ] = getOptions(data, 'Locations.retailer');
	[ storeNumberOptions, filteredData ] = getLimitedOptions(filteredData, 'Locations.storeNumber', retailerFilter);

	[ countryOptions, filteredData ] = getOptions(data, 'Locations.country');
	[ provinceOptions, filteredData ] = getLimitedOptions(filteredData, 'Locations.province', countryFilter);
	[ cityOptions, filteredData ] = getLimitedOptions(filteredData, 'Locations.city', provinceFilter);

	return(
		<Widget
			backgroundColor={theme.palette.background.light} 
			border={ '1px solid ' + theme.palette.secondary.main} 
			disableWidgetMenu
			overflow='visible' >



<Grid
	container
	direction="column"
	spacing={4}>



		<Grid item
			container
			direction="row"
			spacing={2}>

			<Grid 
				item 
				container
				xs={6}
				direction="column" >
					
				<Grid item>
					<MultiSelect
						placeholder="Measure..."
						theme={classes.multiSelect}
						defaultValue={[measureOptions[0]]}
						options={measureOptions}
						onChange={(value) => {
							console.log(value);
						}  }
					/>
				</Grid>
			</Grid>

		</Grid>

		<Grid item
			container
			direction="row"
			spacing={2} >

			<Grid 
				item 
				container
				xs={6}
				direction="column" >

				<div 
					className={classes.section}>

					<Typography variant="h6"> Retailer </Typography>
					<Grid item>
						<Typography variant="overline" display="block"> Retailer </Typography>
						<MultiSelect
							isMulti
							placeholder="Filter by Retailer..."
							theme={classes.multiSelect}
							options={retailerOptions}
							onChange={(value) => {
								filterCallback(value, "Locations.retailer", setRetailerFilter);
							}  }
						/>
					</Grid>
					<Grid item>
						<Typography variant="overline" display="block"> Store Number </Typography>
						<MultiSelect
							isMulti
							placeholder="Filter by Store Number..."
							options={storeNumberOptions}
							onChange={(value) => {
								filterCallback(value, "Locations.storeNumber", setStoreNumberFilter);
							}}
						/>
					</Grid>
					<Grid item>
					</Grid>

				</div>



			</Grid>

			<Grid 
				item 
				container
				xs={6}
				direction="column" >
				
				<div className={classes.section}>

					<Typography variant="h6"> Location </Typography>

					<Grid item>
						<Typography variant="overline" display="block"> Country </Typography>
						<MultiSelect
							isMulti
							placeholder="Filter by Country..."
							options={countryOptions}
							onChange={(value) => {
								filterCallback(value, "Locations.country", setCountryFilter);
							}  }
						/>
					</Grid>
					<Grid item>
						<Typography variant="overline" display="block"> State/Province </Typography>
						<MultiSelect
							isMulti
							placeholder="Filter by State/Province..."
							options={provinceOptions}
							onChange={(value) => {
								filterCallback(value, "Locations.province", setProvinceFilter);
							}}
						/>
					</Grid>
					<Grid item>
						<Typography variant="overline" display="block"> City </Typography>
						<MultiSelect
							isMulti
							placeholder="Filter by City..."
							options={cityOptions}
							onChange={(value) => {
								filterCallback(value, "Locations.city", setCityFilter);
							}}
						/>
					</Grid>

				</div>



			</Grid>

		</Grid>

</Grid>


		</Widget>
	);

}




export default function ChartRenderer(props){
	const renderProps = useCubeQuery(query);
	const theme = useTheme();
	const classes = useStyles();
	return(  render(props, renderProps, theme, classes)  );
		
}
