import React, {useState} from "react";
import { Grid } from "@material-ui/core";
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { map } from 'lodash'
import moment from 'moment'



// components
import WidgetTable from "../../components/WidgetTable";
import PageTitle from "../../components/PageTitle";
import VersionDropdown from "../../components/VersionDropdown";
import FullScreenLoader from "../../components/FullScreenLoader";

// utils
import Axios from "../../utils/Axios";
import checkPing from '../../utils/checkPing';

import { Button } from "../../components/Wrappers";


const GET_DISPLAYS = gql`
  query campaignByOrganization($id: ID!){
    campaignByOrganization(id: $id){
      id,
      title,
      displays{
        id,
        did,
        updated_at,
        version{
          id,
          title
        },
        target{
          id,
          title
        },
        status,
        ping,
        location{
          locationNumber,
          title,
          retailer,
          address,
          city,
          province,
          language
        }
      },
      versions{
        id,
        title
      },
    }
  }
`;


const states = {
  "updated": "primary",
  "updating": "warning",
  "initializing": "warning",
  "unknown": "warning",
  "error": "secondary",
};


function updateDisplays(selectedRows, tableData, setSelectedRows, versionId, setIsFetching, dataObject){

  if(parseInt(versionId) === -1) return;

  setIsFetching(true);
  let items = selectedRows.lookup;
  let requests = []

  map( items, (item, key) => {

    if(item === true){
      let display = dataObject[key];
      if(parseInt(display.version.id) === parseInt(versionId) || display.status === 'Initializing')
        return;

      const requestUrl = 'displays/' + display.id;
      
      const data = {
        'target': versionId,
        'status': {
          label: 'Initializing',
          timestamp: moment().toISOString()
        }
      }
      let request = Axios.put(requestUrl, data);
      requests.push(request);
    }
  });


  if (requests.length < 1){
    setIsFetching(false);
    return;
  }

  
  Promise.all(requests)
  .then(function(res){
    window.location.reload(); 
  })
  .catch(function(err){
    console.log(err);
  });
  
}



export default function Displays(props) {

  const campaignId = props.match.params.id;

  // local
  var [versionSelectState, setVersionSelectState] = useState(-1);
  var [isFetching, setIsFetching] = useState(false);

  return (

    <Query query={GET_DISPLAYS} variables={{id: campaignId}} fetchPolicy={'no-cache'}>
        {({ loading, error, data }) => {
          if(loading || isFetching) return ( <FullScreenLoader /> )
          if (error) {
            console.log(error);
            return <div>{JSON.stringify(error)}</div>
          }

          const displays = data.campaignByOrganization.displays;

          const options = {
            filterType: "checkbox",
            textLabels: {
              body: {
                noMatch: "Sorry, no matching records found",
                toolTip: "Sort",
                columnHeaderTooltip: column => `Sort for ${column.label}`
              },
              selectedRows: {
                text: "display(s) selected",
                delete: "Delete",
                deleteAria: "Delete Selected Rows",
              },
            },
            customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {

              return(
                <div>
                <Grid 
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                >
                  <div>Target Version: </div>
                  <div>
                    <VersionDropdown id={campaignId} value={versionSelectState} onChange={(e) => {
                        const versionId = e.target.value;
                        setVersionSelectState(versionId);
                        updateDisplays(selectedRows, displayData, setSelectedRows, versionId, setIsFetching, displays);
                      }}/>
                  </div>
                </Grid>
                </div>
              );
            },
          }

          return(<div>

            <PageTitle title="Displays" />
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <WidgetTable
                    title="Displays"
                    data={displays}

                    columns={[
                      {name:"id", label: "ID",  options: { display: false, viewColumns: false, filter: false } },
                      {name:"did", label: "Display ID", options: {  filter: false } },
                      {name:"location.retailer", label: "Retailer"},
                      {name:"location.locationNumber", label: "Store ID", options: { filter: false } },
                      {name:"location.address", label: "Address", options: { display: false, filter: false } },
                      {name:"location.city", label: "City"},
                      {name:"location.province", label: "Province", options: { display: false } },
                      {name:"location.language", label: "Language", options: { display: false } },
                      {name:"version.title", label: "Current Version"},
                      {name:"target.title", label: "Target Version"},
                      {name:"status", label: "Status", options: { 
                        filter: false,
                        customBodyRender: (status) => {


                          if(status === null)
                            status = {
                              label: 'Unknown',
                              timestamp: null
                            };


                          const color = states[status.label.toLowerCase()]
                          return <Button 
                            color={color}
                            size="small"
                            className="px-2"
                            variant="contained"
                          >{status.label}</Button>
                        }}
                      },
                      {name:"ping", label: "Ping", options: {
                        filter: false,
                        customBodyRender: (ping) => {
                          
                          const pingStatus = checkPing(ping);

                          return <Button 
                            color={pingStatus.color}
                            title={pingStatus.last}
                            size="small"
                            className="px-2"
                            variant="contained"
                          >{pingStatus.text}</Button>
                        }}
                      }

                    ]}
                    options={options}
                  />
                </Grid>
              </Grid>
            </div>
          )

        }}
      </Query>










    
  );
}
