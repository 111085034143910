import React from "react";

// components
import Chart from "./Chart";

export default function ChartRenderer({
		measures,
		filters, 
		dateRange
	}){

	const chart = {
		query: {
			"measures": measures,
			"timeDimensions": [{
				dimension: "Sessions.timestamp",
				dateRange: dateRange 
			}],
			"dimensions": [
				"Locations.storeNumber",
				"Locations.city",
				"Locations.province"

			],
			"filters": filters
		}
	};

	return(
		<Chart vizState={chart} />
	)

};
