

export function formatLineData (resultSet, theme) {
  const colors = theme.palette.chart.contrast;

  const data = resultSet.series().map((series) => ({
    key: series.title, values: series.series
  }));

  let plotlyData = [];

  data.forEach( (series, key) => {

    let x = []; let y = [];
    series.values.forEach( (point) => { 
      x.push(point.x); 
      y.push(point.value); 
    });

    plotlyData.push({
      x: x, 
      y: y, 
      type:'scatter', 
      mode:'lines+markers', 
      name: series.key,
      marker: {
        size:'2',
        color: colors[key],
        line: {
          color: colors[key],
          width: '2'
        }
      },
    });
  });

  return(plotlyData);
}


export function formatBarData (resultSet, theme) {
  
  const colors = theme.palette.chart.contrast;

  const data = resultSet.series().map((series) => ({
    key: series.title, values: series.series
  }));

  let plotlyData = [];

  data.forEach( (series, key) => {
    let x = []; let y = [];
    series.values.forEach( (point) => { 
      x.push(point.x); 
      y.push(point.value); 
    });
    plotlyData.push({
      x: x, 
      y: y, 
      type:'bar', 
      name: series.key,
      marker: {
        color: colors[key],
      },
    });
  });

  return(plotlyData);
}



export function formatPieData (resultSet, colors) {
  // Prepare data in D3 format
  const data = resultSet.series().map((series) => ({
    key: series.title, values: series.series
  }));

  let plotlyData = [];

  data.forEach( (series) => {
    let x = []; let values = [];
    series.values.forEach( (point) => { 
      x.push(point.x); 
      values.push(point.value); 
    });
    plotlyData.push({
      hole: .75,
      labels: x, 
      values: values, 
      type:'pie', 
      name: series.key,
      automargin: true,
      hoverinfo: 'label+value',
      textinfo: 'none',
      marker: {
        colors: colors,
        line: {
          color: '#FFFFFF',
          opacity: '0.5',
          width: '4'
        }
      },
    });
  });

  return(plotlyData);
}



export function getNumber (resultSet, measure) {

  return resultSet.loadResponse.data[0][measure];

}
