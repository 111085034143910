import React, {useState} from "react"
import  { Redirect } from 'react-router-dom'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { get } from 'lodash'

// components
import VersionDropdown from "../../components/VersionDropdown"
import FullScreenLoader from "../../components/FullScreenLoader"

import VersionForm from "../../components/VersionForm"
import LibraryModal from '../../components/LibraryModal';

// utils
import Axios from "../../utils/Axios"
import auth from "../../utils/auth"


const GET_VERSION_FORM = gql`
  query campaignByOrganization($id: ID!){
    campaignByOrganization(id: $id){
        id,
        versions{
          id,
          title,
          content
        },
        contentStructure
    }
  }
`;


function onCompleted(data, versionId, setContentState, setFieldState){
    if(data === null || data === undefined) return;
    const fields = data.campaignByOrganization.contentStructure.fields;
    const versions = data.campaignByOrganization.versions;
    const version = versions.find(item => { return(parseInt(item.id) === parseInt(versionId)) });
    const contentArray = get(version,'content');
    let content = {};
  for(let i in contentArray){
    let obj = contentArray[i];
    content[obj.name] = obj.value;
  }
  setContentState(content);
  setFieldState(fields)
}


// Handler for text fields
function handleChange({ target }, contentState, setContentState){
  setContentState({ ...contentState, [target.name]: target.value });
}



// Handlers for Modal / Asset Picker
function clearAll(fieldName, contentState, setContentState){
  console.log(fieldName);
  setContentState({ ...contentState, [fieldName]: null });
}

function onSelect (selected, setModalState, selectFieldState, contentState, setContentState){
    console.log(selected);
    console.log(selectFieldState);
    setModalState(false);
    let newData = get(contentState, selectFieldState);
    if(!newData) newData = [];
    newData.push(selected);
    let newState = contentState;
    newState[selectFieldState] = newData;
    setContentState(newState);
}



function onVersionSelect(versionId, versions, setVersionSelectState, setContentState){
  if(versionId === -1) return;

  setVersionSelectState(versionId);

  const version = versions.find( (item) => { return item.id === versionId })
  const contentArray = version.content;
  let contentObj = {};
  for(let i in contentArray){
    let obj = contentArray[i];
    contentObj[obj.name] = obj.value;
  }

  return( setContentState(contentObj) );

}


function onSubmit(campaignId, contentState, setIsFetching, setRedirectState){

  setIsFetching(true);


  const title = get(contentState, 'title');
  const content = contentState;
  const cid = campaignId;

  // Check fields eventually...
  // Convert pure object to list for storage for app convenience

  let fields = []
  let k;
  for (k of Object.keys(content)) {

    fields.push({
      name: k,
      value: content[k],
      type: typeof content[k]
    });

  }

  var bodyFormData = new FormData();
  var data = {
    'title': title,
    'content': fields,
    'campaign': cid
  }
  bodyFormData.append('data', JSON.stringify(data));

  const request = '/versions';
  
  Axios({
    'method': 'post',
    'url': request,
    'data': bodyFormData,
    'headers': {
      Authorization: "Bearer " + auth.getToken() 
    }
  }).then(function (res) {
    //self.setState({ redirect: true });
    setRedirectState(true);

  })
  .catch(function (err) {
    //handle error
    alert("Something went wrong...");
    console.log(err);
  }); 
}



export default function VersionsNew(props) {

  const campaignId = props.match.params.id;
  const versionId = props.match.params.vid;

  // local
  var [versionSelectState, setVersionSelectState] = useState(-1);
  var [isFetching, setIsFetching] = useState(false);

  var [modalState, setModalState] = useState(false);
  var [contentState, setContentState] = useState(null);
  var [fieldState, setFieldState] = useState(null);
  var [selectFieldState, setSelectFieldState] = useState(null);

  var [redirectState, setRedirectState] = useState(false);


  //modal
  const handleOpen = (fieldName) => {
    setModalState(true);
    setSelectFieldState(fieldName);
  };

  const handleClose = () => {
    setModalState(false);
    setSelectFieldState(null);
  };

  const renderRedirect = () => {
    if (redirectState) {
      return <Redirect to={'/app/'+ campaignId +'/versions/view'} />
    }
  }


  return (
    <div>
      {renderRedirect()}

      <LibraryModal 
        id={campaignId} 
        open={modalState} 
        onClose={handleClose} 
        onSelect={(selected) => onSelect(selected, setModalState, selectFieldState, contentState, setContentState) }/>

      <Query query={GET_VERSION_FORM} variables={{id: campaignId}} onCompleted={(data) => {return onCompleted(data, versionId, setContentState, setFieldState)} } >
        {({ loading, error, data }) => {
          if(loading || isFetching) return ( <FullScreenLoader /> )
          if (error) {
            console.log(error);
            return <div>{JSON.stringify(error)}</div>
          }

          const versions = data.campaignByOrganization.versions;

          return(
            <div>
              <VersionDropdown id={campaignId} value={versionSelectState} onChange={(e) => {
                const versionId = e.target.value;
                onVersionSelect(versionId, versions, setVersionSelectState, setContentState);
              }}/>
              <VersionForm 
                version={versionId} 
                content={contentState} 
                fields={fieldState} 
                handleChange={(e) => handleChange(e, contentState, setContentState)} 
                readOnly={false} 
                openModal={handleOpen}
                clearAll={(e) => clearAll(e, contentState, setContentState)}
                onSubmit={(e) => onSubmit(campaignId, contentState, setIsFetching, setRedirectState)}
                isFetching={isFetching}
                />
            </div>

          )
        }}
      </Query>

    </div>

  );
}