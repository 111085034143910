export const dimensionOptions = [
	{label: "None", value: "none"},
	{label: "Retailer", options: [
		{label: "Retailer", value: "retailer"},
		{label: "Store Number", value: "storeNumber"}
	]},
	{label: "Location", options: [
		{label: "City", value: "city"},
		{label: "Province", value: "province"},
		{label: "Country", value: "country"},
	]},
	{label: "Pageview", options: [
		{label: "Page", value: "page"},
		{label: "Page Title", value: "pageTitle"},
	]},
	
];

export const dimensionLookup = {
	"none": [],
	"page": ["Events.page"],
	"pageTitle": ["Events.pageTitle"],
	"city": ["Locations.city"],
	"province": ["Locations.province"],
	"country": ["Locations.country"],
	"retailer": ["Locations.retailer"],
	"storeNumber": ["Locations.storeNumber"]
};
