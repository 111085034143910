import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  TrendingUp as UIElementsIcon,
  PictureInPictureAlt as DisplaysIcon,
  LibraryBooks as LibraryIcon,
  Code as VersionsIcon,
  ArrowBack as ArrowBackIcon,
  Public as GlobeIcon,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

  function structure(campaignId){
    if(!campaignId) campaignId = "select";

    const structure = [
    { id: 0, label: "Dashboard", link: "/app/"+campaignId+"/dashboard", icon: <HomeIcon /> },
    { id: 1, label: "Map", link: "/app/"+campaignId+"/map", icon: <GlobeIcon /> },
    {
      id: 2,
      label: "Analytics",
      icon: <UIElementsIcon />,
      children: [
        { label: "Sessions", link: "/app/"+campaignId+"/analytics/sessions" },
        { label: "Pageviews", link: "/app/"+campaignId+"/analytics/pageviews" },
        { label: "Events", link: "/app/"+campaignId+"/analytics/events" },
      ],
    },
    { id: 3, 
      label: "Displays", 
      link: "/app/"+campaignId+"/displays", 
      icon: <DisplaysIcon /> 
    },
    {
      id: 4,
      label: "Versions",
      icon: <VersionsIcon />,
      link: "/app/"+campaignId+"/versions/view",
      children: [
        { label: "View", link: "/app/"+campaignId+"/versions/view" },
        { label: "Create", link: "/app/"+campaignId+"/versions/new" },
      ],
    },
    {
      id: 5,
      label: "Library",
      link: "/app/"+campaignId+"/library",
      icon: <LibraryIcon />,
    },
  ];

  return(structure);
} 

function Sidebar(props) {
  const campaignId = props.match.params.id;
  const location = props.location;
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure(campaignId).map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
