import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import {Modal } from '@material-ui/core';
import { Query } from 'react-apollo'
import gql from 'graphql-tag'


import AssetTable from '../../components/AssetTable'
import FullScreenLoader from "../../components/FullScreenLoader"


const useStyles = makeStyles(theme => ({
	modal:{
		border: '0px',
	},

	grid:{
		height: "100VH",
		width: "100VW"
	},

	table: {
		position: 'absolute',
		overflowY: "auto",
		width: "700px",
		maxHeight: "550px",
		top: "50%",
		left: "50%",
    	transform: `translate(-250px, -250px)`,
	},
}));


const GET_ASSETS = gql`
  query campaignByOrganization($id: ID!){
    campaignByOrganization(id: $id){
      id,
      title,
      assets{
        id,
        title,
        created_at,
        file{
          id,
          url,
          mime,
          size
        }
      }
    }
  }
`;


export default function LibraryModal({id, open, onClose, onSelect}) {

	const campaignId = id;

	const classes = useStyles();

	return(
      	<Modal
	      aria-labelledby="simple-modal-title"
	      aria-describedby="simple-modal-description"
	      open={open}
	      onClose={onClose}
	      className={classes.modal}
	      
	      >
	     	 <div>
		      <Query query={GET_ASSETS} variables={{id: campaignId}} onCompleted={(data) => {return} }>

		          {({ loading, error, data }) => {
		            if(loading) return (
            			<FullScreenLoader />
	            	)
		            if (error) {
		              console.log(error);
		              return <div>{JSON.stringify(error)}</div>
		            }

		            const assets = data.campaignByOrganization.assets;

		            return(
		                <div className={classes.table}>
		                  <AssetTable assets={ assets } select="single" onSelect={onSelect} />
		                </div>
		          );
		          }}
		        </Query>
	        </div>
	    </Modal>
	);
}