import React from "react";
import {
  Grid,
} from "@material-ui/core";

// Charts Definitions
import SessionsToday from "./components/SessionsToday";
import SessionsLineGraph from "./components/SessionsLineGraph";
import DisplayPerformance from "./components/DisplayPerformance";
import DisplayBreakdownPie from "./components/DisplayBreakdownPie";

// components
import PageTitle from "../../components/PageTitle";


export default function Dashboard(props) {

  return (
    <div>
      <PageTitle title="Dashboard"/>
      <Grid container spacing={4}>
        
        <Grid item lg={5} md={12} sm={12} xs={12}>
          <SessionsToday />
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <DisplayPerformance />
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <DisplayBreakdownPie />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <SessionsLineGraph />
        </Grid>

      </Grid>
    </div>
  );
}
