import React from "react";
import { Typography } from "../../components/Wrappers";
import MomentUtils from '@date-io/moment';
import getStyle from './styles';

import {
	Grid
} from "@material-ui/core";

import {
	DatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';


export default function datetimePicker({
	startDate, setStartDate,
	endDate, setEndDate
}){

	const classes = getStyle();

	return(
		<Grid 
			container
			spacing={2}
			direction="row"
			justify="flex-end"
			alignItems="flex-end"
			style={{marginRight:20}}

			>
			<Grid item>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<DatePicker value={startDate} onChange={setStartDate} format="MMM Do, YYYY" className={classes.datetimePicker}/>
				</MuiPickersUtilsProvider>
			</Grid>
			<Grid item>
				<Typography>to</Typography>
			</Grid>
			<Grid item>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<DatePicker value={endDate} onChange={setEndDate} format="MMM Do, YYYY" className={classes.datetimePicker}/>
				</MuiPickersUtilsProvider>
			</Grid>
		</Grid>

	);
}