import React from 'react'
import { map, get } from 'lodash'
import Thumbnail from '../../components/Thumbnail'
import { Grid, FormControl, FormLabel, InputLabel, Input, Button } from '@material-ui/core';
import { Button as StyledButton } from '../../components/Wrappers'
import { Add as AddIcon, LayersClear as LayersClearIcon } from '@material-ui/icons';

export default function VersionFormInput(props){

  	const key = props.key;
  	const field = props.field;
  	const value = props.value;

  	// Standard Text Input

  	if(get(field, 'type') === 'text'){
		return(<FormControl>
			<InputLabel className="label">{get(field, 'title')} </InputLabel>
			<Input
			autoFocus={key === 0}
			key={get(field, 'name')}
			className={get(field, 'className')}
			onChange={props.handleChange}
			label={get(field, 'label')}
			name={get(field, 'name')}
			readOnly={props.readOnly}
			placeholder={get(field, 'placeholder')}
			type={get(field, 'type')}
			validations={{ required: true }}
			value={value} />
		</FormControl>);
	}

	// Multi Asset Input
	
	return(
		<div>
			<FormControl>
			<FormLabel className="label">{get(field, 'title')} </FormLabel>
			</FormControl>

			{ !value ? (
				<div style={{"paddingBottom": "16px", "paddingTop": "16px"}}>
					<StyledButton variant="contained" color="warning">
						No asset assigned
					</StyledButton>
				</div>
			) : (
				<div style={{"paddingTop":"10px", "paddingBottom":"10px"}}>
					<Grid
						container
						direction="row"
						justify="flex-start"
						alignItems="center"
						spacing={2}
					>
						{ map(value, (asset, key) => (
							<Grid item key={key} > <Thumbnail item={asset} /> </Grid>
						)) }
					</Grid>
				</div>
			)}

			{ !props.readOnly ? (
				<Grid container spacing={2} direction="row">
					<Grid item>
						<Button variant="outlined" color="primary" onClick={() => props.openModal(get(field, 'name'))} ><AddIcon/></Button>
					</Grid>
					<Grid item>
						<Button variant="outlined" color="secondary" onClick={() => props.clearAll(get(field, 'name'))} ><LayersClearIcon/></Button>
					</Grid>
				</Grid>
			) : (<div />)}
		</div>
	);
}