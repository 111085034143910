import React, { useState } from "react";
import {
  Grid,
  Select,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core";

// components
import { Typography } from "../../../../components/Wrappers";
import Widget from "../../../../components/Widget";
import Chart from "./Chart";

// styles
import useStyles from "../../../../pages/dashboard/styles";



const dimensionLookup = {
	"none": [],
	"city": ["Locations.city"],
	"province": ["Locations.province"],
	"country": ["Locations.country"],
	"retailer": ["Locations.retailer"],
	"storeNumber": ["Locations.storeNumber"]
}

export default function ChartRenderer(){


	var [dateRange, setDateRange] = useState("Last 30 days");
	var [dimensionSelect, setDimensionSelect] = useState("none");
	var [dimension, setDimension] = useState([]);
	var [granularity, setGranularity] = useState("day");

	var classes = useStyles();

	var timeDimension = {
		dimension: "Sessions.timestamp",
		granularity: granularity,
	};

	if(dateRange !== "All time") timeDimension.dateRange = dateRange;

	var chart = {
		query: {
			measures: ["Sessions.count"],
			timeDimensions: [timeDimension],
			"dimensions": dimension,
			filters: []
		}
	};

	return(
	  	<Widget
			bodyClass={classes.mainChartBody}
			header={
			  <div className={classes.mainChartHeader}>
				<Typography
				  variant="h5"
				  color="text"
				  colorBrightness="secondary"
				>
				  Sessions
				</Typography>
				<div>
				<Grid container spacing={2}>
				  
				  <Grid item>
					  <Select
						value={dimensionSelect}
						onChange={e => {
							setDimensionSelect(e.target.value);
							setDimension(dimensionLookup[e.target.value]);
						}}
						input={
						  <OutlinedInput
							labelWidth={0}
							classes={{
							  notchedOutline: classes.mainChartSelectRoot,
							  input: classes.mainChartSelect,
							}}
						  />
						}
						autoWidth
					  >
						<MenuItem value={"none"}>None</MenuItem>
						<MenuItem value={"city"}>City</MenuItem>
						<MenuItem value={"province"}>Province</MenuItem>
						<MenuItem value={"country"}>Country</MenuItem>
						<MenuItem value={"retailers"}>Retailer</MenuItem>
						<MenuItem value={"storeNumber"}>Store Number</MenuItem>
					  </Select>
				  </Grid>
				  


				  <Grid item>
					  <Select
						value={dateRange}
						onChange={e => setDateRange(e.target.value)}
						input={
						  <OutlinedInput
							labelWidth={0}
							classes={{
							  notchedOutline: classes.mainChartSelectRoot,
							  input: classes.mainChartSelect,
							}}
						  />
						}
						autoWidth
					  >
						<MenuItem value="All time">All time</MenuItem>
						<MenuItem value="Yesterday">Yesterday</MenuItem>
						<MenuItem value="This week">This week</MenuItem>
						<MenuItem value="This month">This month</MenuItem>
						<MenuItem value="This quarter">This quarter</MenuItem>
						<MenuItem value="This year">This year</MenuItem>
						<MenuItem value="Last 7 days">Last 7 days</MenuItem>
						<MenuItem value="Last 30 days">Last 30 days</MenuItem>
						<MenuItem value="Last week">Last week</MenuItem>
						<MenuItem value="Last month">Last month</MenuItem>
						<MenuItem value="Last quarter">Last quarter</MenuItem>
						<MenuItem value="Last year">Last year</MenuItem>
					  </Select>
				  </Grid>
				  


				  <Grid item>
					<Select
					value={granularity}
					onChange={e => setGranularity(e.target.value)}
					input={
					  <OutlinedInput
						labelWidth={0}
						classes={{
						  notchedOutline: classes.mainChartSelectRoot,
						  input: classes.mainChartSelect,
						}}
					  />
					}
					autoWidth
					>
					<MenuItem value="hour">Hourly</MenuItem>
					<MenuItem value="day">Daily</MenuItem>
					<MenuItem value="week">Weekly</MenuItem>
					<MenuItem value="month">Monthly</MenuItem>
					<MenuItem value="year">Yearly</MenuItem>
					</Select>
				  </Grid>


				</Grid>
				</div>
			  </div>
			}
		>	

			<Chart vizState={chart}/>

		</Widget>
	)
};
