import React from "react";
import {Grid, CircularProgress } from "@material-ui/core";

export default function FullScreenLoader(){
	return(
		<Grid 
            container
            style={{height: "100%", padding: "20px 20px"}}
            justify="center"
            alignItems="center"
            >
            <CircularProgress size={20} />
    	</Grid>
	)
}