import React from "react";
import { Grid, Button } from "@material-ui/core";
import DatetimePicker from "../../components/DatetimePicker";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";

export default function PageTitle(props) {
  var classes = useStyles();

  return (
    <div className={classes.pageTitleContainer}>
      <Typography className={classes.typo} variant="h1" size="sm">
        {props.title}
      </Typography>

      <div>
        <Grid
          container
          spacing={4}
          direction='row'>

        { props.datetimePicker && 
          <Grid item>
            <DatetimePicker 
            startDate={props.startDate}
            setStartDate={props.setStartDate}
            endDate={props.endDate}
            setEndDate={props.setEndDate}
           />
           </Grid>
        }

        {props.button && (
          <Grid item>
            <Button
              classes={{ root: classes.button }}
              variant="contained"
              size="large"
              color="secondary"
              href={props.href}
              onClick={props.onClick}

            >
              {props.button}
            </Button>
          </Grid>
        )}
        </Grid>
      </div>
    </div>
  );
}
