import axios from 'axios';
import auth from '../../utils/auth'

const create = function() {


	let obj = {
		//baseURL: 'http://localhost:1337',
		baseURL: process.env.REACT_APP_INSTINCT_CMS,
		timeout: 0,
	}
	let token = auth.getToken();

	if(token){
		obj.headers = {Authorization: "Bearer " + auth.getToken()};
	} 

	return(axios.create(obj))
}

const instance = create();
export default instance;