import React from 'react';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Videocam as VideocamIcon } from '@material-ui/icons';
import { Grid, Card, CardContent, CardMedia, Typography } from "@material-ui/core";


import './styles.css';

const useStyles = makeStyles(theme => ({
  root: {
  	width: 50,
  	height: 50
  },
  media: {
    height: 50,
    paddingTop: '56.25%', // 16:9
    cursor: 'pointer'
  },
  video:{
  	height: 50,
  	width: 50,
  	background:"#333",
  	cursor: 'pointer'
  }
}));





export default function Thumbnail({item}) {
	
	const classes = useStyles();

	const src = get(get(item,'file'), 'url');
	const title = get(item, 'title');
	const mime = (get(get(item,'file'), 'mime'));

	if(!mime || !src || get(item,'file') == null){
		console.log("Invalid JSON format");
		return(<div className="notification is-danger"><p><b>ERROR:</b> A file was not found. Please contact support.</p></div>);
	}


	if( mime.includes("image") ){
		return(
			<Card className={classes.root}>
				<CardMedia 
					className={classes.media}
					image={src} 
					onClick={() => window.open(src)}
					/> 
				
				<CardContent>
					<Typography variant="body2" color="textSecondary" component="p">
						{title}
					</Typography>
				</CardContent>

			</Card>
		)
	}
	return(
		<Card className={classes.root}>
			<Grid
				container
			    direction="row"
			    justify="center"
			    alignItems="center"
		    	className={classes.video}
		    	onClick={() => window.open(src)}
			    >
					<VideocamIcon  />
			</Grid>
			<CardContent>
				<Typography variant="body2" color="textSecondary" component="p">
					{title}
				</Typography>
			</CardContent>

		</Card>
	)

}
