import React, {useState} from "react"
import { Grid, Button } from "@material-ui/core"
import {DropzoneArea} from 'material-ui-dropzone'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'

// components
import FullScreenLoader from "../../components/FullScreenLoader"
import AssetTable from "../../components/AssetTable"

// utils
import Axios from "../../utils/Axios"
import auth from "../../utils/auth"


const GET_ASSETS = gql`
  query campaignByOrganization($id: ID!){
    campaignByOrganization(id: $id){
      id,
      title,
      assets{
        id,
        title,
        created_at,
        file{
          url,
          mime,
          size
        }
      }
    }
  }
`;


function handleFile(file, setFileState){
  console.log(file);
  setFileState(file);
}


function uploadFile(campaignId, fileState, setIsFetching){
  setIsFetching(true);
  const file = fileState[0];

  if(!file){
    console.log("No File Found");
    return;
  }

  let bodyFormData = new FormData();
  let data = {
    'campaign': campaignId,
    'file': file,
    'title': file.name
  }

  // Check 50 MB limit
  if(file.size > 50000000){ 
    return;
  } 

  bodyFormData.append('title', "New File!");
  bodyFormData.append('files.file', file, file.name);
  bodyFormData.append('data', JSON.stringify(data));

  const request = '/assets';

  Axios({
    'method': 'post',
    'url': request,
    'data': bodyFormData,
    'headers': {
      Authorization: "Bearer " + auth.getToken() 
    }
  }).then(function (res) {
    console.log(res);
    window.location.reload();
  })
  .catch(function (err) {
    //handle error
    console.log(err);
  }); 


}

export default function Versions(props) {

  const campaignId = props.match.params.id;

  // local
  var [isFetching, setIsFetching] = useState(false);
  var [fileState, setFileState] = useState([]);


  return(
    <Query query={GET_ASSETS} variables={{id: campaignId}} onCompleted={(data) => {return} }>
      {({ loading, error, data }) => {
        if(loading || isFetching) return ( <FullScreenLoader /> )
        if (error) {
          console.log(error);
          return <div>{JSON.stringify(error)}</div>
        }

        const assets = data.campaignByOrganization.assets;

        return(
          <Grid 
            container 
            spacing={2}
            direction="column"
          >
            <Grid 
              container item 
              spacing={2}
              direction="column"
            >
              <Grid item>
                <DropzoneArea 
                  dropzoneText="Drag & drop a .JPG, .PNG, or .MP4"
                  filesLimit={1}
                  maxFileSize={50000000}  // 50 MB
                  acceptedFiles={['image/jpeg', 'image/png', 'video/mp4']}
                  onChange={(file) => handleFile(file, setFileState)}
                />
              </Grid>
              <Grid item style={{"textAlign":"right"}}>
                { fileState.length > 0 ?
                  <Button variant="contained" color="primary" onClick={ () => uploadFile(campaignId, fileState, setIsFetching) }>Add File</Button>
                : 
                  <div />
                }
              </Grid>
            </Grid>
            <Grid item>
              <AssetTable 
                assets={ assets } />
            </Grid>
          </Grid>
        );
      }}
    </Query>
    );

}