import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from "@material-ui/core"
import moment from 'moment';

import {Check as CheckIcon} from '@material-ui/icons';

import WidgetTable from "../../components/WidgetTable";
import Thumbnail from '../../components/Thumbnail';


function rowClick({lookup}, displayData, assets, onSelect){
  const selected = assets.find( (item, key) => {
    return lookup[key] === true
  } );

  return( onSelect(selected) );
}


const useStyles = makeStyles(theme => ({
  check:{
    margin: theme.spacing(1),
  },
}));


export default function AssetTable({assets, select, onSelect}){
  const classes = useStyles();

  if(select == null) select="none";

  const options = {
    selectableRows: select,
    filterType: "checkbox",
    textLabels: {
      body: {
        noMatch: "Sorry, no matching records found",
        toolTip: "Sort",
        columnHeaderTooltip: column => `Sort by ${column.label}`
      },
      selectedRows: {
        text: "display(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
    customToolbarSelect: (selectedRows, displayData) => {
      return(
          <IconButton onClick={ () => rowClick(selectedRows, displayData, assets, onSelect)} className={classes.check}>
            <CheckIcon />
          </IconButton>
        );
      },
  }

	return(
		<WidgetTable
            title="Assets"
            data={assets}
            columns={[
              {name:"id", label: "ID",  options: { display: false, viewColumns: false, filter: false } },
              {name:"id", label: "Thumbnail", options: {filter: false, customBodyRender: (value, tableMeta, updateValue) => {
                const asset = assets.find( (item) => { return item.id === value } );
                return (<Thumbnail item={asset}/>);
              }}},
              {name:"title", label: "Title", options: { filter: false }},
              {name:"file.mime", label: "Type"},
              {name:"created_at", label: "Created", options: {customBodyRender: (value, tableMeta, updateValue) => {
                return (moment(value).format('MMM Do, YYYY'));
              }}}
            ]}
            options={options}
          />
	)

}