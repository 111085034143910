import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
	container: {
		backgroundColor: theme.palette.secondary.light
	},

	section: {

		border: "1px solid #00000010",
		borderRadius: 5,
		padding: 10,
		height:"100%"
	}
}));
