import React from 'react';
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import Plot from 'react-plotly.js';


//components
import FullScreenLoader from "../../components/FullScreenLoader";

// styles
import useStyles from "./styles";
import { useTheme } from "@material-ui/styles";

const GET_DISPLAYS = gql`
  query campaignByOrganization($id: ID!){
    campaignByOrganization(id: $id){
      id,
      title,
      displays{
        id,
        did,
        status,
        ping,
        updated_at,
        location{
          locationNumber,
          title,
          retailer,
          address,
          postal,
          city,
          province,
          language,
          latitude,
          longitude
        }
      }
    }
  }
`;



const layout = {
    dragmode: "zoom",
    mapbox: { style: "carto-positron", center: { lat: 38, lon: -90 }, zoom: 3 },
    margin: { r: 0, t: 0, b: 0, l: 0 },
    legend: {
      x: .99,
      xanchor: 'right',
      y: 0.96
    }
  };




export default function Chart(props, {vizState, callback}){

  const campaignId = props.match.params.id;

  var classes = useStyles();
  var theme = useTheme();



  return (
    <Query query={GET_DISPLAYS} variables={{id: campaignId}} >
      {({ loading, error, data }) => {
        if(loading) return ( <FullScreenLoader /> )
        if (error) {
          console.log(error);
          return <div>{JSON.stringify(error)}</div>
        }

        const displays = data.campaignByOrganization.displays;


        const plotData = [{
          name: "Good",
          type: "scattermapbox",
          hoverinfo: 'text',
          text: [],
          lat: [],
          lon: [],
          opacity: 0.5,
          marker: { color: theme.palette.primary.main, size: 20 }
        }, {
          name: "Alert",
          type: "scattermapbox",
          hoverinfo: 'text',
          text: [],
          lat: [],
          lon: [],
          opacity: 0.8,
          marker: { color: theme.palette.warning.main, size: 20 }
        }, {
          name: "Error",
          type: "scattermapbox",
          hoverinfo: 'text',
          text: [],
          lat: [],
          lon: [],
          opacity: 0.8,
          marker: { color: theme.palette.secondary.main, size: 20 }
        }];

        function addToMap(display, object){
          if(display.location === null)
            return;

          object.lat.push(  parseFloat(display.location.latitude)  );
          object.lon.push(  parseFloat(display.location.longitude)  );
          
          let status = "Unknown";
          if(display.status !== null){
            status = display.status.label;
          }

          let text = display.did
            + "<br>" + display.location.retailer + " - #" + display.location.locationNumber
            + "<br>" + display.location.address
            + "<br>" + display.location.postal + ", " + display.location.city + ", " + display.location.province
            + "<br><br><b>Status: " + status + "</b>";

          object.text.push(  text  );
        }

        displays.forEach((display) => {
          switch(0){
            case 0:
              addToMap(display, plotData.find(item => item.name === "Good"));
              break;
            case 1:
              addToMap(display, plotData.find(item => item.name === "Alert"));
              break;
            case 2:
              addToMap(display, plotData.find(item => item.name === "Error"));
              break;
            default:
              addToMap(display, plotData.find(item => item.name === "Good"));
              break;
          } 

        })


        


        return(<div className={classes.mapContainer}>
            <Plot
            
              style={{height:"100%", width:"100%"}}
              data={ plotData }
              layout={ layout }
              autoOpen={false}
              config={null}
              useResizeHandler={true}
            />
          </div>
        );

        /*
        return (
          <div className={classes.mapContainer}>
            <ReactMapGL 

              height="100%"
              width="100%"
              className={classes.map}
              mapboxApiAccessToken={token}
              onClick={handleClick}
              onViewportChange={(viewport) => { setViewport(viewport) }}
              {...viewport}
            >
              <Pins classes={classes} theme={theme} data={displays} onClick={(display) => onClickMarker(display, popup, setPopup)}/>
              <Popup state={popup} onClose={ () => onClose(setPopup) }  classes={classes}/>
            </ReactMapGL>
          </div>
        );
        */
      }}
    </Query>
  );
}

