import React, { useState } from "react";
import moment from 'moment';
import {
  Grid,
} from "@material-ui/core";


// components
import PageTitle from "../../components/PageTitle";

import PageviewsFilters from "./components/PageviewsFilters";
import PageviewsLineGraph from "./components/PageviewsLineGraph";
import PageviewsBarGraph from "./components/PageviewsBarGraph";
import PageviewsTable from "./components/PageviewsTable";
import PageviewsMap from "./components/PageviewsMap";

function generateFilter(array){
  let filters = [];
  array.forEach((item => {
    if (item.values.length > 0) filters.push(item);
  }));
  return(filters)
}

export default function Pageviews(props) {

  const [showFilters, setShowFilters] = useState('none');
  const [startDate, setStartDate] = useState(  moment().add(-3, 'months').startOf('day')  );
  const [endDate, setEndDate] =     useState(  moment().endOf('day')  );

  const [measureState, setMeasureState] = useState(["Events.countPageviews"]);
  
  const [retailerFilter, setRetailerFilter] = useState([]);
  const [storeNumberFilter, setStoreNumberFilter] = useState([]);
  
  const [countryFilter, setCountryFilter] = useState([]);
  const [provinceFilter, setProvinceFilter] = useState([]);
  const [cityFilter, setCityFilter] = useState([]);

  const [pageFilter, setPageFilter] = useState([]);
  const [pageTitleFilter, setPageTitleFilter] = useState([]);


  const filters = generateFilter([
    retailerFilter,
    storeNumberFilter,
    countryFilter,
    provinceFilter,
    cityFilter,
    pageFilter,
    pageTitleFilter, 
  ]);


  return (
    <div>
      
      <PageTitle 
        title="Pageviews" 
        datetimePicker
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        button={showFilters==='none' ? 'Options' : 'Hide'} 
        onClick={() => {
        if(showFilters === 'block') setShowFilters('none');
        else setShowFilters('block');
      }}/>


      <Grid container spacing={4}>

        <Grid item lg={12} md={12} sm={12} xs={12} style={{display: showFilters}}>
          <PageviewsFilters 
            measureState={measureState}
            setMeasureState={setMeasureState}

            retailerFilter={retailerFilter}
            setRetailerFilter={setRetailerFilter}
            storeNumberFilter={storeNumberFilter}
            setStoreNumberFilter={setStoreNumberFilter}

            countryFilter={countryFilter}
            setCountryFilter={setCountryFilter}
            provinceFilter={provinceFilter}
            setProvinceFilter={setProvinceFilter}
            cityFilter={cityFilter}
            setCityFilter={setCityFilter}

            pageFilter={pageFilter}
            setPageFilter={setPageFilter}
            pageTitleFilter={pageTitleFilter}
            setPageTitleFilter={setPageTitleFilter}
          />
        </Grid>

        <Grid item lg={6} md={12} sm={12} xs={12}>
          <PageviewsLineGraph measures={measureState} filters={filters} dateRange={[startDate, endDate]} />
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <PageviewsMap measures={measureState} filters={filters} dateRange={[startDate, endDate]} />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <PageviewsBarGraph measures={measureState} filters={filters} dateRange={[startDate, endDate]} />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <PageviewsTable measures={measureState} filters={filters} dateRange={[startDate, endDate]} />
        </Grid>
      </Grid>
    </div>
  );
}
