import React from "react";
import { Grid, Link } from "@material-ui/core";
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';

// components
import PageTitle from "../../components/PageTitle";
import FullScreenLoader from "../../components/FullScreenLoader";
import WidgetTable from "../../components/WidgetTable";

const GET_VERSIONS = gql`
  query campaignByOrganization($id: ID!){
    campaignByOrganization(id: $id){
      id,
      title,
      versions{
        id,
        title,
        created_at
      },
    }
  }
`;

export default function Versions(props) {

  const campaignId = props.match.params.id;
  const options = {
    selectableRows: 'none',
    filterType: "checkbox",
    textLabels: {
      body: {
        noMatch: "Sorry, no matching records found",
        toolTip: "Sort",
        columnHeaderTooltip: column => `Sort for ${column.label}`
      },
      selectedRows: {
        text: "display(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },

  }


  return (
    <Query query={GET_VERSIONS} variables={{id: campaignId}} >
        {({ loading, error, data }) => {
          if( loading ) return ( <FullScreenLoader /> )
          if (error) {
            console.log(error);
            return <div>{JSON.stringify(error)}</div>
          }

          const versions = data.campaignByOrganization.versions;

          return(
            <div>

              <PageTitle title="Versions" button="Add New Version" href={"/#/app/"+campaignId+"/versions/new"} />
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <WidgetTable
                    title="Versions"
                    data={versions}
                    columns={[
                      {name:"id", label: "ID",  options: { display: false, viewColumns: false, filter: false } },
                      {name:"title", label: "Title", options: {customBodyRender: (value, tableMeta, updateValue) => {
                        const versionId = tableMeta.rowData[0];
                        return (<Link href={"#/app/"+campaignId+"/versions/view/"+versionId}>{value}</Link>);
                      }}},
                      {name:"created_at", label: "Created", options: {customBodyRender: (value, tableMeta, updateValue) => {
                        return (moment(value).format('MMM Do, YYYY'));
                      }}}
                    ]}
                    options={options}
                  />
                </Grid>
              </Grid>
            </div>
          );


        }}
      </Query>
  );
}