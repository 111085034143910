import React from 'react';
import MultiSelect from 'react-select';
import { useTheme } from "@material-ui/styles";
import useStyles from "./styles";

export default function StyledMultiSelect(props){
	
	const Theme = useTheme();
	const classes = useStyles();

	return(
		<MultiSelect
			className={classes.MultiSelect}
			{...props}
			theme={theme => ({
	      ...theme,
	      colors: {
	        ...theme.colors,
	        primary: Theme.palette.primary.main,
	        primary25: Theme.palette.primary.feather,
	      },
	    })}
		/>
	)

}
