import { makeStyles } from "@material-ui/styles";


export default makeStyles(theme => ({
	mapContainer: {
		margin: -theme.spacing(1) * 3,
		marginTop: 0,
		overflow: "hidden"
	},

	map: {
		height: "500px",
		width: "100%",
		margin: "10px"
	},

	icon: {
		cursor: 'pointer',
		stroke: 5,
		height: 20,
		width: 20,
	},
  	
	good:{
		fill: theme.palette.primary.main + "80",
		"&:hover": {
	  		fill: theme.palette.primary.main + "FF"
		}
	},

	popup:{
		height:	"200px",
		width: 	"300px"

	}


}));